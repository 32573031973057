import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoStream from "../components/img/LogoStream"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ContactUsPage = () => {
  return (
    <Layout>
      <Seo title={`Contact Us`} description={``} />

      <LogoStream />

      <div className="content-wrapper">
        <div className="main-with-sidebar">
          <h1>Contact us</h1>
          <p>
            Got a question? You may find the answer on our{" "}
            <Link to="/gift-cards/faq/">FAQs page</Link>. If not, contact our
            Gift Card Guest Services on{" "}
            <a href="tel:03316305409">03316305409</a>, opening hours are 8am to
            8pm, 7 days a week, or email on{" "}
            <a href="mailto:mabgiftcards@bhnetwork.com">
              mabgiftcards@bhnetwork.com
            </a>
            .
          </p>

          <p>
            If your query relates to one of our Dining Out locations, contact
            the manager of the relevant{" "}
            <a href="/national-search/">restaurant, pub or bar</a>, who'll be
            more than happy to help you.
          </p>

          <h2>Business enquiry?</h2>
          <p>
            Contact us on <a href="tel:03302369310">03302369310</a>, opening
            hours are 8am to 8pm, 7 days a week or email{" "}
            <a href="mailto:mabforbusiness@bhnetwork.com">
              mabforbusiness@bhnetwork.com
            </a>
            .
          </p>
        </div>

        <div className="sidebar">
          <div className="box">
            <a
              href="https://diningout.cashstar.com/"
              rel="noreferrer"
              target="_blank"
              className="w-full"
            >
              <StaticImage
                src="../../static/boxes/buy-now-2.png"
                alt="Dining Out Card"
                className="w-full"
              />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
